import { Box, BoxProps, Stack } from '@mui/material';
import {
  motion,
  type MotionValue,
  useSpring,
  useTransform,
} from 'framer-motion';
import { atom, useAtom } from 'jotai';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { PresicavHv } from 'src/assets/styles/fonts';
import { useBreakpoint } from 'src/hooks/use-breakpoint';
import { MyDevices } from 'src/utils/helpers/etc';

const MAGIC_WORD = 'ubet';
const words = ['slot', 'roll', 'win!', 'cash', 'spin', 'race', 'play'];

// const charsets = _.chain([...words, MAGIC_WORD])
//   .map((x) => x.split(''))
//   .unzip()
//   .map((x) =>
//     _.chain(x)
//       .uniq()
//       .orderBy((x) => x, 'desc')
//       .value(),
//   )
//   .value();
// console.log(charsets);
const charsets = [
  ['w', 'u', 's', 'r', 'p', 'c'],
  ['p', 'o', 'l', 'i', 'b', 'a'],
  ['s', 'o', 'n', 'l', 'i', 'e', 'c', 'a'],
  ['y', 't', 'n', 'l', 'h', 'e', '!'],
];

// 'abcdefghijklmnopqrstuvwxyz'.split('');
const duration = 700;
const HEIGHT = 120;
const HEIGHT_MOBILE = HEIGHT / 2;

const Wrapper: React.FCC<BoxProps> = ({ children, ...boxProps }) => {
  return (
    <Box
      sx={{
        flex: 1,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        // border: '4px dashed red',
        // '&:hover .reel': {
        //   overflow: 'visible',
        //   zIndex: -1,
        // },
      }}>
      <Box
        sx={{
          display: 'flex',
          gap: { xs: 1, sm: 2 },
          fontSize: { xs: HEIGHT_MOBILE - 16, sm: HEIGHT - 32 },
          color: '#1D222E',
          textTransform: 'uppercase',

          '& .reel': {
            overflow: 'hidden',
            bgcolor: '#fff',
            width: { xs: HEIGHT / 2, sm: HEIGHT },
            height: { xs: HEIGHT / 2, sm: HEIGHT },
            textAlign: 'center',
            verticalAlign: 'middle',
            lineHeight: 1,
            ...PresicavHv.style,
            fontWeight: 700,
            position: 'relative',
          },
          '& .char': {
            position: 'absolute',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            bgcolor: '#fff',
            '&[title="u"]': {
              bgcolor: '#0BA8F8',
            },
          },
          ...boxProps?.sx,
        }}>
        {children}
      </Box>
    </Box>
  );
};

export const Static: React.FCC = () => {
  return (
    <Stack
      sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        bgcolor: '#1D222E',
        zIndex: 9999,
      }}>
      <Wrapper>
        {MAGIC_WORD.split('').map((x) => (
          <div className="reel" key={x}>
            <span className="char" title={x}>
              {x}
            </span>
          </div>
        ))}
      </Wrapper>
    </Stack>
  );
};

export enum AppState {
  LOADING = 1, // display loading screen
  READY = 2, // ready but wait for loading screen finish its thing
  DONE = 3, // main content
}

export const appStateAtom = atom(AppState.LOADING);

const Animated: React.FCC = () => {
  const [text, setText] = useState(MAGIC_WORD);
  const [appState, setAppState] = useAtom(appStateAtom);

  useEffect(() => {
    if (appState === AppState.DONE) return;

    if (appState === AppState.READY) {
      setText(MAGIC_WORD);
      const token = setTimeout(() => {
        setAppState(AppState.DONE);
      }, 1000);
      return () => {
        clearTimeout(token);
      };
    }

    setText(_.sample(words) ?? '');
    const token = setInterval(() => {
      setText((prev) => {
        let next = prev;
        while (prev === next) {
          next = _.sample(words) ?? '';
        }
        return next;
      });
    }, duration);

    return () => {
      clearInterval(token);
    };
  }, [appState]);

  return (
    <Wrapper>
      {text.split('').map((x, i) => {
        return <Reel key={i} charset={charsets[i]} value={x} />;
      })}
    </Wrapper>
  );
};

const Reel: React.FCC<{ charset: string[]; value: string }> = ({
  charset,
  value,
}) => {
  const nextIndex = charset.findIndex((x) => x === value);
  const motion = useSpring(nextIndex, { duration: duration - 200 });

  useEffect(() => {
    const prevIndex = Math.round(motion.getPrevious() ?? 0) % charset.length;

    const offset = nextIndex - prevIndex;
    if (offset < 0) {
      // offset += charset.length;
      // console.log('============');
      // console.log(charset);

      // console.log({
      //   offset,
      //   prevIndex,
      //   nextIndex,
      //   prev: charset[prevIndex],
      //   next: charset[nextIndex],
      // });

      motion.jump(charset.length - 1);
    }

    motion.set(nextIndex + charset.length);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [motion, nextIndex]);

  return (
    <div className="reel">
      {charset.map((x, i) => (
        <Char key={x} charset={charset} mv={motion} char={x} />
      ))}
    </div>
  );
};

const Char: React.FC<{ mv: MotionValue; charset: string[]; char: string }> = ({
  mv,
  char,
  charset,
}) => {
  const mobile = useBreakpoint(MyDevices.MOBILE);
  const height = mobile ? HEIGHT_MOBILE : HEIGHT;

  const y = useTransform(mv, (latest: number) => {
    const prev = latest % charset.length;
    const next = charset.findIndex((x) => x === char);

    let offset = (charset.length + next - prev) % charset.length;

    if (offset > charset.length / 2) {
      offset -= charset.length;
    }

    return offset * height * -1;
  });

  return (
    <motion.span title={char} className="char" style={{ y }}>
      {char}
    </motion.span>
  );
};
